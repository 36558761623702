<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + instrumental.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + instrumental.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'AdminInstrumentalEdit', params: {id: instrumental.id}}">
        {{ instrumental.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link :to="{name: 'UserDetail', params: {id: instrumental.uploader.id}}" v-if="instrumental.uploader">
        {{ instrumental.uploader.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(instrumental.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'SongDetail', params: {id: instrumental.id, slug: instrumental.slug, prefix: 'dan-ca'}}">
        Xem
      </router-link>
      <router-link :to="{name: 'AdminInstrumentalEdit', params: {id: instrumental.id}}" class="mr-3">
        Sửa
      </router-link>
      <a href="javascript:void(0)" v-on:click="deleteInstrumental(instrumental)">Xóa</a>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminInstrumentalItem",
  props: {
    instrumental: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    deleteInstrumental(instrumental) {
      this.$emit("deleteInstrumental", instrumental);
    }
  },
  created() {
    this.isChecked = this.instrumental.is_checked;
  },
  watch: {
    "instrumental.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedInstrumental", this.instrumental, newVal);
      }
    },
  }
}
</script>
